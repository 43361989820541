<template>
  <div class="d-flex align-items-center">
    <a-icon
      type="paper-clip"
      v-if="assignmentType === 'File Upload'"
      style="font-size: 18px; color: #1b55e3"
    />
    <a-icon
      type="align-left"
      v-else-if="assignmentType === 'Essay'"
      style="font-size: 18px; color: #1b55e3"
    />
    <a-icon
      type="unordered-list"
      v-else-if="assignmentType === 'Multiple Choice'"
      style="font-size: 18px; color: #1b55e3"
    />
    <a-icon
      type="unordered-list"
      v-else
      style="font-size: 18px; color: #1b55e3"
    />
    <span
      v-if="withText"
      class="ml-2"
      style="font-size: 14px; color: #1b55e3"
      >{{ assignmentType }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    // 'File Upload', 'Essay', 'Multiple Choice'
    assignmentType: {
      type: String,
      required: true,
    },
    withText: {
      type: Boolean,
      default: () => false,
    },
  },
}
</script>

<style>
</style>
